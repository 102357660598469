$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "~bootstrap4-fullscreen-modal/src/bootstrap4-modal-fullscreen.scss";

@import "styles/khs_einreichungen";
@import "styles/cookiebanner";
@import "~bootstrap/scss/bootstrap";
@import "styles/swiper/pagination";
@import "styles/swiper/slider";
//@import "~bootstrap4-fullscreen-modal/src/bootstrap4-modal-fullscreen.scss";

@import "styles/application";
@import "styles/headings";
@import "styles/program";
@import "styles/footer";
@import "styles/text";
@import "styles/listing";
// @import "styles/slideshow/all";
@import "styles/accounts";
@import "styles/gallery/caption";


html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-size: 1.0rem;
  background: #f8f8f8;

  @include media-breakpoint-up(lg) {
    font-size: 1.1rem;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 0;
    //font-size: 1.1rem;
  }
}

/* make room for the nav bar */
div.row.section[id] {
  padding-top: 70px;
  margin-top: -35px;
  margin-bottom: 2rem;
}

div.row.intro[id] {
  padding-top: 40px;
}
.hover\:text-secondary:hover {
  color: $secondary;
}

.min-h-75 {
  min-height: 40vh;
}

.h-20 {
  height: 6rem;
}

.h-10 {
  height: 3rem;
}

.relative {
  position: relative;
  overflow: hidden;
}

.w-20 {
  width: 20%;
}

.w-33 {
  width: 33.3333%;
}

.img-object-center {
  object-position: center center;
  object-fit: contain;
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  max-height: 5rem;
}

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, 0);
}

.py-1px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.9rem;
}

.text-gray-700 {
  color: $gray-700;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.leading-tight {
  line-height: 1.1;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.bg-gray-300 {
  background-color: $gray-300;
}
.bg-gray-400 {
  background-color: $gray-400;
}

.modal.modal-fullscreen {
  padding-right: 0px !important;
}

.modal-fullscreen .close {
  display: inline-block;
  text-align: right;
  align-self: flex-end;
  padding-right: 1rem;
  margin: 0;
  margin-bottom: -1rem;
  font-size: 4rem;
  position: fixed;
  z-index: 1;
}

.header {
  margin-top: 70px;
  margin-bottom: 70px;
}

.header__intro {
  font-size: 150%;
  text-transform: uppercase;
  font-weight: 200;
  margin-bottom: 3rem;
  margin-top: 2rem;
  font-family: $font-family-sans-serif;

  strong, b {
    font-weight: 900;
  }
}

.navbar-brand {
  font-family: $font-family-sans-serif;
  font-weight: 200;
  text-transform: uppercase;
}

.navbar.navbar-khs_einreichungen {
  //height: 5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  @include media-breakpoint-up(lg) {
    font-size: 1.0rem;
    padding-right: 0;

    &.navbar-expand-lg .navbar-nav .nav-link {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .dropdown-menu {
      padding: 0.15rem 0;
      font-size: 1.0rem;
    }
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.2rem;

    &.navbar-expand-lg .navbar-nav .nav-link {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
    }

    .dropdown-menu {
      font-size: 1.2rem;
    }
  }

  .navbar-brand-logo {
    width: 12rem;
    position: relative;
    top: 0rem;
    left: -0.5rem;
    padding: 0.125rem;
  }

  @include media-breakpoint-up(lg) {
    .navbar-brand-logo {
      width: 20rem;
      top: 0rem;
      left: 0;
      padding: 0.5rem;
    }
  }
}

.badge {
  font-family: $font-family-sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.fixed {
  position: fixed;
}

.bottom-0 {
  bottom: 0;
}

.break-word {
  word-break: break-all;
}

.w-100\+30 {
  left: -15px;
  width: calc(100% + 30px);
}

.-mb-1 {
  margin-bottom: -0.25rem;
}

[x-cloak] {
  display: none !important;
}

.absolute {
  position: absolute;
}

.transition {
  transition: opacity;
}
.duration-200 {
  transition-duration: 200ms;
}

.delay-200 {
  transition-delay: 200ms;
}

.ease-in {
  transition-timing-function: ease-in;
}
.ease-out {
  transition-timing-function: ease-out;
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.cursor-default {
  cursor: default;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rating-frame-label {
  border: 1px solid $primary;
  padding: 0 0.5rem;
  background: white;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  & > span {
    padding: 0 0.5rem;
    text-overflow: ellipsis " [..]";
    overflow: hidden;
    height: 2.1rem;
  }
}

.rating-frame-header {
  height: 2.1rem;
  overflow: hidden;
}

.rating-frame-buttons {
  min-width: max-content;
}

.bg-list-active {
  background: #eff9f2;
  border: 1px solid $primary !important;
}

.col-0 {
  flex: 0 0 0%;
  max-width: 0;
  width: 0;
  overflow: hidden;
}

.fullscreen-toggle {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.text-justify {
  text-align: justify;
}

.leading-6 {
  line-height: 1.5;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, 1fr);
}

.gap-y-6 {
  grid-row-gap: 1.5rem;
}
.gap-x-4 {
  grid-column-gap: 1rem;
}

.gap-y-4 {
  grid-row-gap: 1rem;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.gap-2 {
  grid-gap: 0.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.h-8 {
  height: 2rem;
}

.w-6 {
  width: 1.5rem;
}

.h-5 {
  height: 1.25rem;
}

.w-5 {
  width: 1.25rem;
}

.not-required::after {
  content: "" !important;
}

.avoid-page-break {
  page-break-inside: avoid;
}
